import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import About from './components/About';
import Mission from './components/Mission';
import Services from './components/Services';
import How from './components/How';
import Consult from './components/Consult';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ServicesPage from './components/ServicesPage';
import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop

function App() {
  const consultRef = useRef(null);
  const missionRef = useRef(null); // Create ref for the Mission component
  const howRef = useRef(null); // Create ref for the How component
  const [showMission, setShowMission] = useState(false);
  const [showHow, setShowHow] = useState(false);

  // Toggle visibility of the Mission section
  const handleGetToKnowUsClick = () => {
    setShowMission((prevShowMission) => !prevShowMission);
  };

  // Show Mission section when "About" is clicked in Navbar
  const handleAboutClick = () => {
    setShowMission(true);
    setTimeout(() => {
      if (missionRef.current) {
        missionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0); // Delay scroll until after state change
  };

  // Show the How It Works section and scroll immediately
  const handleHowItWorksClick = () => {
    setShowHow(true); // Show How It Works
    setTimeout(() => {
      if (howRef.current) {
        howRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0); // Delay scroll until after state change
  };

  const { i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  };

  useEffect(() => {
    if (showMission && missionRef.current) {
      missionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showMission]);

  return (
    <Router>
      <ScrollToTop /> {/* Add this component */}
      <div className="App">
        <Navbar
          onLanguageChange={handleLanguageChange}
          onAboutClick={handleAboutClick} // Pass the handler to Navbar
        />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Intro consultRef={consultRef} />
                <div id="about">
                  <About onGetToKnowUsClick={handleGetToKnowUsClick} />
                </div>
                {showMission && (
                  <div ref={missionRef}>
                    <Mission />
                  </div>
                )}
                <Services onHowItWorksClick={handleHowItWorksClick} />
                {showHow && (
                  <div ref={howRef}>
                    <How />
                  </div>
                )}
                <div id="consult">
                  <Consult ref={consultRef}  />
                </div>
                <Team />
                <div id="contact">
                  <Contact />
                </div>
                <Footer />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <ServicesPage />
                <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
