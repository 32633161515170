import React from 'react';
import './Team.css';
import { useTranslation } from 'react-i18next';

// Importing images
import Ahmad from './ahmad-odeh.jpeg';
import Firas from './firas-salhi.png';
import Iyas from './iyas-bahloul.png';
import Moayad from './moayad-salman.png';
import Saad from './saad-salman.png';

const teamMembers = [
  // Founders section
  {
    name: 'د. أحمد عودة',
    title: 'شريك طبي',
    brief: 'الدكتور أحمد عودة هو أخصائي أشعة تشخيصية وتداخلية...',
    imageUrl: Ahmad, // Imported image
  },
  {
    name: 'أ. علي حمد الله',
    title: 'شريك تنفيذي',
    brief: 'علي حمدالله هو خبير في تطوير الأعمال والمشاريع...',
    imageUrl: Ahmad, // Imported image
  },

  // Specialists section
  {
    name: 'د. مؤيد سلمان',
    title: 'أخصائي أشعة تشخيصية وأشعة أطفال',
    brief: 'بخبرة تزيد عن 10 أعوام، ويعمل الآن كأخصائي للأشعة التشخيصية في مستشفى المقاصد – القدس. تخرج الدكتور سلمان من كلية الطب من جامعة قائد أعظم – الباكستان، وتابع تدريبه التخصصي في الأشعة التشخيصية في مستشفى المقاصد – القدس، وأكمل تخصصه الفرعي في أشعة الأطفال في كندا من جامعة تورونتو – مستشفى سيك كدز (Sick Kids Hospital)، كما يعمل كأستاذ مساعد في كليّة الطب في جامعة القدس – القدس. ',
    imageUrl: Moayad, // Imported image
  },
  {
    name: 'د. فراس الصالحي',
    title: 'أخصائي الأشعة التشخيصية والقسطرة الدماغية والطرفية',
    brief: 'تخرّج من الجامعة العبرية – مستشفى شعري تسيدك، وحاصل على البورد الفلسطيني في الأشعة، وزمالة الكلية الملكية البريطانية في الأشعة، وعمل محاضراً ومنسقاً لبرامج التدريب لطلّاب كليّة الطب في الجامعة العبرية – مستشفى شعاري تسيدك – قسم الأشعة.  ',
    imageUrl: Firas, // Imported image
  },

  // Advisory section
  {
    name: 'د. سعد سلميان',
    title: 'اخصائي أشعة تشخصية',
    brief: 'تخرج الدكتور سعد سليمان من كلية الطب من جامعة النجاح الوطنية، ومن ثم التحق ببرنامج التخصص في الاشعة التشخصية في مستشفى المقاصد – القدس، حاصل على البورد الفلسطيني وحاصل على المرتبة الثانية في البورد العربي على مستوى الوطن العربي كما انه التحق بعديد من المؤتمرات ودورات التعليم عن بعد خارج البلاد',
    imageUrl: Saad, // Imported image
  },
  // New advisory member
  {
    name: 'إياس بهلول',
    title: 'المستشار القانوني',
    brief: '',
    imageUrl: Iyas, // Imported image
  }
];

const Team = () => {
  const { t } = useTranslation();
  
  return (
    <div id="team" className="team-container text-center my-16">
      <h2 className="team text-4xl font-bold">{t('team.title')}</h2>
      <div className='team-p'>
        <p>{t('team.paragraph')}</p>
      </div>

      {/* Founders */}
      <div className="team-category">
        <h3 className="category-title mt-10">{t('team.founders')}</h3>
        <div className="team flex flex-wrap justify-center gap-8">
          {teamMembers.slice(0, 2).map((member, index) => (
            <div key={index} className="team-member text-center">
              <div className="w-40 h-40 rounded-full overflow-hidden mx-auto mb-4">
                <img
                  src={member.imageUrl}
                  alt={`${member.name}'s profile`}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-bold">{member.name}</h3>
              <p className="text-gray-600">{member.title}</p>
              <p className="text-gray-500 mt-2 team-pb">{member.brief}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Specialists */}
      <div className="team-category">
        <h3 className="category-title">{t('team.specialists')}</h3>
        <div className="team flex justify-center gap-8">
          {teamMembers.slice(2, 5).map((member, index) => (
            <div key={index} className="team-member text-center">
              <div className="w-40 h-40 rounded-full overflow-hidden mx-auto mb-4">
                <img
                  src={member.imageUrl}
                  alt={`${member.name}'s profile`}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-bold">{member.name}</h3>
              <p className="text-gray-600">{member.title}</p>
              <p className="text-gray-500 mt-2 team-pb">{member.brief}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Advisory */}
      <div className="team-category">
        <h3 className="category-title">{t('team.advisory')}</h3>
        <div className="team flex justify-center gap-8">
          {teamMembers.slice(5, 7).map((member, index) => (
            <div key={index} className="team-member text-center">
              <div className="w-40 h-40 rounded-full overflow-hidden mx-auto mb-4">
                <img
                  src={member.imageUrl}
                  alt={`${member.name}'s profile`}
                  className="w-full h-full object-cover"
                />
              </div>
              <h3 className="text-xl font-bold">{member.name}</h3>
              <p className="text-gray-600">{member.title}</p>
              <p className="text-gray-500 mt-2 team-pb">{member.brief}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
